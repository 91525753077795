import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ontimize-web-ngx';

@Injectable({
  providedIn: 'root'
})
export class StorageCleanerService {
  private readonly BROWSER_OPEN_KEY = 'expedweb-browser-open';

  constructor(private localStorageService: LocalStorageService) {
    this.initializeCleaner();
  }

  private initializeCleaner() {
    if (!sessionStorage.getItem(this.BROWSER_OPEN_KEY)) {
      // El navegador se acaba de abrir, limpiar localStorage
      this.cleanLocalStorage();
    }

    // Marcar que el navegador está abierto
    sessionStorage.setItem(this.BROWSER_OPEN_KEY, 'true');
  }

  private cleanLocalStorage() {
    try {
      this.localStorageService.setLocalStorage({});
    } catch (error) {
      console.error('Error al limpiar LocalStorage:', error);
    }
  }
}
